body {
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif; */
  font-family: "Source Sans Pro", "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Roboto", sans-serif;
}

h3,
h4,
h5,
h6 {
  font-weight: 200 !important;
}

@media (max-width: 600px) {
  h2.MuiTypography-h2 {
    font-size: 5rem;
  }

  h5.MuiTypography-h5 {
    font-size: 2.1rem;
  }
}

.Home-header {
  font-family: "Source Sans Pro", "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
}

.list {
  font-size: 1.4rem;
}
